// _theme.scss

* {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
html {
    body {
        margin: 0;
        background: $primary-color;
        color: $white-color;
        font-family: $Inter-font;
        font-weight: $font-regular;
        font-size: $f16;
        overflow-x: $hidden;
        h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6m .h6 {
            font-weight: $font-semibold!important;
            font-family: $Inter-font;
            line-height: 1.2;
        }
    }
}
h1 {
    @media (min-width:1441px) {
        font-size: $f40;
    }
    @media (max-width:1440px) {
        font-size: $f30+8;
    }
    @media (max-width:1200px) {
        font-size: $f30+6;
    }
}
h2, .h2 {
    @media (min-width:1441px) {
        font-size: $f30+6;
    }
    @media (max-width:1440px) {
        font-size: $f30+4;
    }
    @media (max-width:1200px) {
        font-size: $f30+2;
    }
}
h3, .h3 {
    @media (min-width:1441px) {
        font-size: $f30+2;
    }
    @media (max-width:1440px) {
        font-size: $f30;
    }
    @media (max-width:1200px) {
        font-size: $f20+8;
    }
}
h4, .h4 {
    @media (min-width:1441px) {
        font-size: $f20+6;
    }
    @media (max-width:1440px) {
        font-size: $f20+24;
    }
    @media (max-width:1200px) {
        font-size: $f20+2;
    }
}
h5, h5 {
    @media (min-width:1441px) {
        font-size: $f20+2;
    }
    @media (max-width:1440px) {
        font-size: $f20+1;
    }
    @media (max-width:1200px) {
        font-size: $f20;
    }
}
h6 {
    @media (min-width:1441px) {
        font-size: $f20;
    }
    @media (max-width:1440px) {
        font-size: $f16+3;
    }
    @media (max-width:1200px) {
        font-size: $f16+2;
    }
}
ul {
    margin: 0;
    padding: 0;
}
p {
    font-size: $f16;
    &:last-child {
        margin-bottom: 0;
    }
}
img {
    border: 0;
    @include no-select;
    outline: 0 !important;
}
a {
    cursor: $pointer;
    color: $blue-color-200;
    @include transition (all, .5s, ease-in-out);
    &:hover {
        text-decoration: $none;
    }
}
figure {
    margin: 0;
}
hr {
    border-color: rgba($black-color, .3);
}

.bg-white {background: $white-color;}
.bg-grey {background: $sky-blue-color-100;}
.radius-15 {@include border-radius (15px);}

/* Scrollbar */
::-webkit-scrollbar {
    width: 7px;
    height: 7px;
}

/* Track */
::-webkit-scrollbar-track {
    background: $base-color;
    @include border-radius (0px);
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $violet-color-100;
    @include border-radius (0px);
}

// Placeholder CSS
::placeholder {
    color: rgba($black-color, .3);
}
:-ms-input-placeholder {
    color: rgba($black-color, .3);
}
::-ms-input-placeholder {
    color: rgba($black-color, .3);
}

// input[type="date"]::-webkit-inner-spin-button,
// input[type="date"]::-webkit-calendar-picker-indicator,
// input[type="time"]::-webkit-inner-spin-button,
// input[type="time"]::-webkit-calendar-picker-indicator{
// }

input::-webkit-calendar-picker-indicator {
    @include opacity (0);
    position: $absolute;
    width: 100%;
    top: 0;
    height: 100%;
    left: 0;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-text-fill-color: $black-color;
    -webkit-background-clip: unset !important;
    // -webkit-box-shadow: inset 5px 5px 4px  rgb(65 65 65 / 10%), inset -5px -5px 4px rgb(65 65 65 / 10%) !important;
    -webkit-box-shadow: none !important;
}

input {
    &[type=date],
    &[type=datetime-local] {
        background-image: $calendar_icon;
        background-repeat: $no-repeat;
        background-position: 96%;
        background-size: 18px;
    }
}

/* input[type="time"] {background:$white-color url(../img/icons/clock_icon.svg)no-repeat; background-position:96% center; background-size:18px;} */

// Button CSS
label {
    color: $label-color-78;
    font-weight: $font-regular;
    font-size: $f16;
    line-height: 19px;
}

button { outline: 0 !important; box-shadow: $none !important; @include transition(all, 0.3s, ease-in-out);}

.btn {
    cursor: $pointer;
    white-space: nowrap;
    @include transition(all, 0.3s, ease-in-out);
    &[disabled],
    &.disabled {
        @include opacity (0.5);
        color: $white-color;
        pointer-events: $none;
    }
    &-lg {
        min-width: 170px;
        padding: .76rem 1.5rem;
        @media (min-width:1441px){
            padding: 1rem 1.8rem;
            font-size: $f16+2;
        }
        @media (max-width:820px){
            min-width: 140px;
            font-size: $f12+3;
        }
    }
    &-md {
        @include border-radius (7px);
        min-width: 130px;
        padding: .35rem 1.2rem;
        min-height: 44px;
        @media (min-width:1441px){
            min-height: 50px;
        }
        @media (max-width:1200px){
            min-width: 120px;
        }
    }
    &-sm {
        font-size: $f12+2;
        min-height: 20px;
        // min-width: 80px;
        padding: .38rem .78rem;
    }
}

.btn-primary {
    background: linear-gradient(270deg, $blue-color-100 18.04%, $blue-color-200 78.91%);
    border: 2px solid $violet-color-300 !important;
    color: $white-color;
    &:hover, &:active {
        background: linear-gradient(270deg, $blue-color-200 18.04%, $blue-color-100 78.91%);
    }
}

.btn-red {
    color: $white-color;
    background-color: $red-color-600;
    background: linear-gradient(270deg, $red-color-400 10.98%, $red-color-600 100%);
    border: 1px solid $red-color-700!important;
    @include border-radius (5px);
    font-weight: $font-medium;
    cursor: $pointer;
    &:hover {
        background: linear-gradient(270deg, $red-color-600  10.98%, $red-color-400 100%);
        color: $white-color;
        text-decoration: $none;
    }
}

.btn-secondary {
    background: $black-color-700;
    color: $white-color;
    border: 0;
    &:hover {
        background: $dark-blue-color;
        color: $white-color;
    }
}

.btn-transparent {
    background: $transparent;
    border-color: $transparent;
    color: $blue-color-200;
    &:hover {
        color: $blue-color-200;
    }
}
span.btn {
    @media (min-width:1441px){
        line-height: 2.1;
    }
}
.form-group {
    position: $relative;
    margin: 0 0 25px;
    @media (max-width:991px) {
        margin: 0 0 15px;
    }
}

.form-control,
.form-floating .form-control {
    border: 0!important;
    min-height: 44px;
    box-shadow: $none!important;
    background: $sky-blue-color-100;
    outline: 0 !important;
    @include border-radius (7px);
    padding: 16px 18px 14px;
    color: $black-color;
    font-size: $f12+2;
    font-weight: $font-regular;
    @include transition (all, .5s, ease-in-out);
    @media (min-width:1441px) {
        font-size: $f16;
        min-height: 50px;
    }
    &:focus {
        color: $black-color;
        background-color: $sky-blue-color-200;
    }
    &[readonly] {
        background: $base-color;
    }
    &.disabled,
    &:disabled {
        @include opacity (.4);
        background-color: $base-color;
    }
    &-sm {
        min-height: 46px;
        font-size: $f12+2;
        padding: 14px 15px 12px;
        @include border-radius (5px !important);
    }
}

select,
select.form-control {
    background-image: $selectDownArrowBlack;
    background-position: 96% $center;
    background-repeat: $no-repeat;
    background-size: 12px;
    appearance: $none;
    -webkit-appearance: $none;
    text-overflow: ellipsis;
    padding: 14px;
    padding-right: 24px;
    outline: 0 !important;
    text-transform: $capitalize;
}

.dropdown_List {
    .select,
    select.form-control {
        background-image: $selectDownArrow;
        background-repeat: $no-repeat;
        background-size: 12px;
        background-position: 96% $center;
        appearance: $none;
        -webkit-appearance: $none;
        text-overflow: ellipsis;
        padding: 14px;
        padding-right: 24px;
        outline: 0 !important;
        text-transform: $capitalize;
    }
    .react-select {
        &__single-value {
            color: rgba($white-color,1);
        }
    }
}

select option {
    background: $base-color;
    color: $white-color;
}

.custom-control-input[disabled]~.custom-control-label::before,
.custom-control-input:disabled~.custom-control-label::before {
    background-color: $unset;
    @include opacity (.5);
}

// badge
.badge {
    min-width: 80px;
    padding: 8px 10px;
    font-size: $f12;
    color: $white-color;
    font-weight: $font-regular;
    @include border-radius (15px);
    &-danger {
        background: $red-color-400;
    }
    &-success {
        background: $success-color;
    }
    &-warning {
        background: $warning-color;
    }
    &-info {
        background: $info-color;
    }
}

.badge-primary {
    border: 1px solid $blue-color-300;
    background-color: rgba($blue-color-300, .4);
    &:hover {
        background-color: rgba($blue-color-200, .7);
        border: 1px solid $blue-color-200
    }
}

/* Custom Checkbox */
.custom-checkbox {
    input {
        padding: 0;
        height: initial;
        width: initial;
        margin-bottom: 0;
        display: $none;
        cursor: $pointer;

        &:checked {
            + {
                label {
                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        top: 2px;
                        left: 9px;
                        width: 6px;
                        height: 14px;
                        border: solid #ffffff;
                        border-width: 0 2px 2px 0;
                        transform: rotate(45deg);
                    }

                    &:before {
                        background: #160CC7;
                    }
                }
            }
        }
    }

    label {
        position: $relative;
        cursor: $pointer;

        &::before {
            content: '';
            -webkit-appearance: none;
            background-color: transparent;
            border: 1px solid #3A3A3A;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05), inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
            padding: 10px;
            display: inline-block;
            position: $relative;
            vertical-align: middle;
            cursor: pointer;
            margin-right: 10px;
            @include border-radius(3px);
        }

    }
}

.error {
    color: $red-color-400;
    font-size: $f12+2;
    margin: 4px 0 0;
    text-align: $left;
}

sup {
    color: $error-color;
}

// DroDown Toggle
.custom_dropdown {
    .dropdown-toggle {
        color: $white-color;
        background: $transparent;
        padding: 9px 30px 9px 10px;
        @include border-radius (5px);
        border: 1px solid $transparent;
        @include flexbox;
        @include align-items ($center);
        line-height: 19px;
        font-size: $f16;
        @media (max-width:575px) {
            font-size: $f12+2;
        }
        &::after {
            background: $arrow_bottom $no-repeat;
            background-size: 12px;
            background-position: $center;
            border: 0;
            width: 12px;
            height: 13px;
            @include opacity (.85);
            position: $absolute;
            right: 10px;
            top: 19px;
            transform: translateY(-50%);
        }
    }
    .dropdown-menu-end {
        right: -5px;
    }
    &:hover {
        button {
            color: $white-color;
        }
    }
    .dropdown {
        &-menu {
            box-shadow: 0 0 10px rgba(0, 0, 0, .5);
            background-color: rgba($white-color,1);
        }
        &-item {
            color: $black-color;
            &:hover {
                color: $black-color;
            }
        }
    }
}

//
.page_title {
    color: $black-color-300;
}