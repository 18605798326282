@import "../../../../Assets/scss/base.scss";

@import url("//fonts.googleapis.com/css?family=Rye");

.jackport_sec {
  padding: 0 30px;
  background: url(../../../../Assets/Images/jackport_bg.jpg) $no-repeat;
  background-size: $cover;
  @include border-radius (20px);
  figure {
    img {
      @media (min-width:992px){
        width: 400px;
      }
    }
  }
  @media (max-width:767px) {
    @include flex-flow(column);
    padding:30px 15px;
  }
  .col:not(:last-child) {
    @media (max-width:767px) {
      margin-bottom: 20px;
    }
  }
}


.odometer.odometer-auto-theme, .odometer.odometer-theme-slot-machine {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}
.odometer.odometer-auto-theme .odometer-digit, .odometer.odometer-theme-slot-machine .odometer-digit {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  position: relative;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer, .odometer.odometer-theme-slot-machine .odometer-digit .odometer-digit-spacer {
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  *zoom: 1;
  *display: inline;
  visibility: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner, .odometer.odometer-theme-slot-machine .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon, .odometer.odometer-theme-slot-machine .odometer-digit .odometer-ribbon {
  display: block;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner, .odometer.odometer-theme-slot-machine .odometer-digit .odometer-ribbon-inner {
  display: block;
  -webkit-backface-visibility: hidden;
}
.odometer.odometer-auto-theme .odometer-digit .odometer-value, .odometer.odometer-theme-slot-machine .odometer-digit .odometer-value {
  display: block;
  -webkit-transform: translateZ(0);
}
.odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value, .odometer.odometer-theme-slot-machine .odometer-digit .odometer-value.odometer-last-value {
  position: absolute;
}
.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-theme-slot-machine.odometer-animating-up .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s;
}
.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-slot-machine.odometer-animating-up.odometer-animating .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}
.odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner, .odometer.odometer-theme-slot-machine.odometer-animating-down .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%);
}
.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-slot-machine.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.odometer {
  background-image: url(../../../../Assets/Images/dot_glow.png), url(../../../../Assets/Images/dot_glow.png) ;
  background-repeat: repeat-x;
  background-position: 0px -8px, 0px 65px;
  background-size: 35px;
  // background-image: radial-gradient(#F1E56A 0.5px, transparent 10px);
  box-shadow: 0 0 20px rgb(255 255 255 / 60%);
  @media (max-width:767px) {
      overflow: $hidden;
  }
  &::after {
   content: '';
   background: url(../../../../Assets/Images/glow_lft.png);
   background-repeat: repeat-y;
   background-size: 35px;
   width: 40px; height: 73%;
   position: $absolute;
   right: -15px; top: 7px;
   @media (max-width:767px) {
      top:0;
      height: 100%;
   }
  }
  &::before {
    content: '';
    background: url(../../../../Assets/Images/glow_lft.png);
    background-repeat: repeat-y;
    background-size: 35px;
    width: 40px; height: 73%;
    position: $absolute;
    left: -9px; top: 7px;
    @media (max-width:767px) {
      top:0;
      height: 100%;
   }
   }
   &.odometer-auto-theme {
   .odometer-inside {
    .odometer-digit {
      .odometer-digit-spacer {
        @media (max-width:767px) {
            padding:7px;
        }
      }
      .odometer-digit-inner {
        @media (max-width:767px) {
          padding:7px;
      }
      }
    }
   }
  }
}

.odometer.odometer-auto-theme, .odometer.odometer-theme-slot-machine {
  @include border-radius (15px);
  background-color: #B92311;
  border: 3px solid #DDBB6B;
  font-weight: $font-bold;
  line-height: 1.35em;
  font-size: 1.6rem;
  padding: 1rem;
  text-align: $center;
  .odometer-digit {
    .odometer-digit-spacer, .odometer-digit-inner, .odometer-ribbo {
      padding: 10px 16px;
      display: $block;
      text-align: $center;
      border: 1px solid #DDBB6B;
      background-color: #940601;
      background: linear-gradient(180deg, #F40A01 0%, #940601 100%);
      .odometer-value {
        text-align: $center;
        display: $block;
      }
    }
    &:nth-child(2n){
       .odometer-digit-spacer, .odometer-digit-inner, .odometer-ribbo {
          background: #ff0000;
       }
    }
    &:first-child {
      .odometer-digit-spacer, .odometer-digit-inner, .odometer-ribbo {
        @include border-radius (7px 0 0 7px);
      }
    }
    &:last-child {
      .odometer-digit-spacer, .odometer-digit-inner, .odometer-ribbo {
        @include border-radius (0 7px 7px 0);
      }
    }
  }
}