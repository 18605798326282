@import '../../../../Assets/scss/base.scss';

.no-data {
    min-height: 140px;
}
.categories_section {
    padding: 40px 0;
    @media (max-width:575px){
        padding: 20px 0;
    }
}

#scrollableDiv1 {
    overflow-x: $hidden;
    overflow-y: $auto;
    .infinite-scroll-component {
        overflow-x: $hidden!important;
    }
}

.categories_list {
    @media (max-width:767px) {
        width: 100%;
        overflow-x: $auto;
    }
    &_item {
        color: $white-color;
        font-size: $f16;
        line-height: 1.4;
        margin-right: 70px;
        white-space: nowrap;
        color: $violet-color-100;
        font-weight: $font-medium;
        text-transform: $capitalize;
        border-bottom: 3px solid $transparent;
        cursor: $pointer;
        padding: 12px 20px;
        @include transition(all, 0.3s, ease-in-out);
        @media (min-width:1441px) {
            margin-right: 80px;
            font-size: $f20 + 2;
        }
        @media (max-width:767px) {
            margin-right: 30px;
        }
        &:hover{
            color: $white-color;
        }
        &.active {
            color: $white-color;
            font-weight: $font-bold;
            border-color: $white-color;
        }

    }
}

// categoires list
.search_secn_cats {
    .search_box {
        max-width: 500px;
        .form-control {
            background: $blue-color-400;
            border:1px solid $violet-color-500!important;
            color: $white-color;
            &:focus {
                color: $white-color;
                background-color: $blue-color-400;
            }
            &::placeholder {
                color: $violet-color-500;
            }
        }
    }

    .dropdown_List {
        max-width: 250px;
        @media (max-width:575px){
            max-width: 100%;
        }
        .react-select-container {
            .react-select__control {
                box-shadow: $none;
                color: $violet-color-200;
                border: 1px solid $violet-color-500;
                background: url('../../../../Assets/Images/icons/select-down-arrow.svg') no-repeat $blue-color-400;
                background-position: calc(100% - 18px);
                min-height: 50px;
                &:focus {
                    color: $white-color;
                    background-color: $blue-color-400 !important;
                    box-shadow: $none !important;
                }
                &[readonly] {
                    background: $blue-color-400;
                }
            }
        }
    }
}

// for hover bottom text position
// .cate_list_block {
    // max-height: 900px;
    // overflow-y: $auto;
    // overflow-x: $hidden;
// }
@at-root .game_box {
    height: 100%;
    position: $relative;
    overflow: $hidden;
    @include border-radius (10px);
    background-color: $dark-blue-color-200;
    @include transition (all, .5s, ease-in-out);
    padding-bottom: 100%;
    figure {
        height: 100%;
        width: 100%;
        position: $absolute;
        top: 0;
        left: 0;
        background-color: $white-color;
        img {
            width: 100%;
            height: 100%;
            object-fit: $contain;
        }
    }
    .game_card_text {
        position: $absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
        // height: 54px;
        // @include opacity (0);
        background: rgba($black-color, 20%);
        @include border-radius (0 0 10px 10px);
        @include transition (all, .5s, ease-in-out);
        .btn {
            visibility: $hidden;
            @include opacity (1);
            display: $none!important;
        }
        .game_summary {
            padding: 15px;
            .h5 {
                color: $white-color;
                margin: 0;
            }
        }
        @media (max-width:991px){
            height: auto;
            @include opacity (1);
        }
    }
    &:hover {
        .game_card_text {
            @include flexbox;
            @include justify-content ($center);
            @include align-items ($center);
            bottom: 0;
            height: 100%;
            color: $white-color;
            // @include opacity (1);
            background: rgba($black-color, 70%);
            @include transition (all, .5s, ease-in-out);
            .h5 {
                margin-bottom: 20px;
            }
            .btn {
                display: flex!important;
                @include opacity (1);
                visibility: visible;
            }
        }
    }
}