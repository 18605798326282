@import "../../Assets/scss/_base.scss";

.categories_sec {
    padding: 80px 0 20px;
    &.pattern_bg {
        background: url(../../Assets/Images/hexagon_pattern.png) $dark-blue-color-200;
    }
}

.common_title {
    display: $inline-block;
    h2 {
        color:$white-color;
        text-transform: $uppercase;
        border:3px solid $sky-blue-color;
        padding: 15px 45px;
        width: auto;
        margin: 0;
    }
}