@import '../../../Assets/scss/base.scss';

.footer {
    background: $dark-blue-color;
    .footer_pages_links {
        ul {
            li {
                a {
                    font-weight: $font-medium;
                    text-decoration: $none;
                    font-size: $f16;
                    padding: 0 10px 5px;
                    line-height: 40px;
                    text-transform: $uppercase;
                    color: $violet-color-100;
                    cursor: $pointer;
                    border-bottom: 2px solid $transparent;
                    @include transition(all, 0.3s, ease-in-out);
                    &.active, &:hover {
                        color: $white-color;
                        font-weight: $font-medium;
                        border-color: $white-color;
                    }
                }
            }
        }
    }
    .footer_item {
        .h6 {
            img {
                width: 200px;
            }
        }
        p {
            color: $white-color;
        }
    }

    .common_partner {
        flex: 1 1 $auto;
        margin-bottom: 30px;
        padding-bottom: 30px;
        min-height: 70px;
        border-bottom: 1px solid $violet-color-500;
        position: $relative;
        @media (max-width:767px) {
            @include flex-wrap (wrap);
            @include flex-flow (column);
            margin-bottom: 15px;
            padding-bottom:15px;
        }
        .partner_logo {
            @include flexbox;
            @include align-items ($center);
            flex: 1 1 $auto;
            @media (max-width:767px) {
                @include flex-wrap (wrap);
            }
            a {
                margin: 0 ;
                padding: 0 25px;
                @media (max-width:767px) {
                    margin-bottom: 15px;
                    padding-left: 0;
                }
                @media (min-width:768px) and (max-width:1199px) {
                    margin: 15px 0;
                    padding-left: 0;
                }
                img {
                    max-height: 50px;
                    // width: 100px;
                    width: auto;

                }
            }
        }

        .partner_name {
            span {
                line-height: 1.3;
                font-size: $f16 + 2;
                color: $white-color;
                @media (max-width:767px) {
                    display: block;
                }
                @media (min-width:1440px) {
                    font-size: $f20;
                }

            }
        }

        .license_no {
            font-size: $f16;
            color: $white-color;
        }
    }

    .main_footer {
        border-bottom: 1px solid $violet-color-500;
        .h6 {
            color: $white-color;
        }
        .footer_list {
            ul {
                display: grid;
                grid-template-columns: 1fr 1fr;
                column-gap: 1rem;
                list-style: none;
                margin: 0;
                padding: 0;
                li {
                    padding-bottom: 15px;
                    a {
                        @include flexbox;
                        @include align-items ($center);
                        text-decoration: $none;
                        color: $white-color;
                        @include transition(all , 0.3s , ease-in-out);
                        &:hover {
                            text-decoration: $underline;
                            text-decoration: $none;
                            @include opacity (.9);
                        }
                        em {
                            margin-right: 15px;
                            img {
                                width: 34px;
                                height: $auto;
                            }
                        }
                    }
                }
            }
        }
    }

    .copyright_text {
        span {
            color: $violet-color-500;
            font-size: $f12+2;
        }
    }
}