@import "../../Assets/scss/base.scss";

// register page
.register-secn {
    background: url(../../Assets/Images/disney.png) $dark-blue-color-200;
    background-repeat: $no-repeat;
    background-position: $center top;
    background-attachment: $fixed;
    background-size: 100%;
    .h1, .h2, .h3, .h4, .h5, .h6, p {
        color:$black-color;
    }
    @at-root {
        sup.req_txt {
            color: $red-color;
        }
        .form_card {
            @include border-radius (12px);
        }
            .label_title {
            margin-bottom: 40px;
            @media (max-width:1440px) {
                margin-bottom: 30px;
            }
        }
    }
    @at-root {
        .phone_select_drop {
            box-shadow: $none;
            background: $sky-blue-color-100;
            @include border-radius (7px);
            border:0; min-height:50px; padding: 12px;
            color:$black-color; font-size:$f12+2; font-weight: $font-regular;
            @include transition (all, .5s, ease-in-out);
            outline:0!important;
            .react-tel-input {
                .form-control {
                    width:100%;
                    border:1px solid $grey!important;
                    background: $transparent;
                }
                .flag-dropdown {
                    width: 80px;
                    background: $transparent;
                    border: 0;
                    .selected-flag {
                        background: $transparent;
                    }
                }
            }
        }
    }
    .valdiation_box {
        max-width: 350px;
        width: 100%;
        margin-right: 5%;
    }
}


// passwrd view eye icon image
.password-view .pwd-view-img {
	position: $absolute;
    top:30px;
	right: 20px;
	cursor: $pointer;
	color: $black-color;
	@include transform (translateY(-50%));
	img {

		@media (min-width: 1280px) {
			max-width: 20px;
		}
	}
}

.min-vh-60 {
    min-height: 60vh!important;
}

.min-vh-40 {
    min-height: 40vh!important;
}
