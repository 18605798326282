
@import "../../../Assets/scss/base.scss";

.provider_List_row {
    column-gap: 30px;
    place-items: center;
}
.provider_List {
    &_col {
        // width: calc(20% - 30px);
        // flex: 1 1 calc(20% - 30px);
        margin-bottom: 30px;
        padding: 20px;
        // @media (max-width:767px) {
        //     width: calc(50% - 30px);
        //     flex: 1 1 calc(50% - 30px);
        //     margin-bottom: 10px;
        // }
    }
}

.game_providers {
    padding: 40px 0 0;
}